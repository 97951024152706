.user-edit-page {
  &__title-wrapper {
    margin-bottom: 32px;
  }
  &__entry {
    & + & {
      margin-top: 16px;
    }
  }
  &__checkbox {
    margin-bottom: 30px;

    label {
      padding-right: 20px;
    }

    input {
      width: 20px;
      height: 20px;
    }
  }
}
