.role-create {
  padding: 40px 0 0 29px;

  &__title {
    font-weight: 400;
    font-size: rem(32);
    line-height: 122%;
    color: $title;
    margin-bottom: 40px;
  }

  &__prev {
    font-family: 'Inter', sans-serif;
    background: none;
    color: $btn;
    text-align: left;
    margin-top: 20px;
    display: block;
    text-decoration: none;
    font-weight: 600;
    font-size: rem(16);
    line-height: 122%;
    text-transform: uppercase;
    cursor: pointer;
  }

  .input__label {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 122%;
    color: $mainText;
  }
}