.order-info {
	&-entry {
		display: flex;

		& + & {
			margin-top: 16px;
		}

		&__title {
			width: 100%;
			max-width: 180px;
			margin-right: 16px;
		}

		&__children {
			flex-direction: column;
			display: flex;
			> a + a {
				margin-top: 10px;
			}
		}
	}

	&-link {
		color: #7200cc;
	}

	&-button {
		display: block;
		width: 100%;
		max-width: 250px;
		text-align: center;
		text-decoration: none;
	}

	&-label {
		display: block;
		margin-top: 16px;
	}

	&__paymnetText {
		font-size: 24px;
		line-height: 32px;
		color: red;
		text-align: center;
	}
}

.order-info-table {
	margin-top: 48px;

	&__title {
		font-size: 14px;
		margin: 14px 0 4px 0;
	}
}

.button-small {
	display: block;
	max-width: 100px;
	background-color: #7200cc;
	color: #ffffff;
	border-radius: 4px;
	border-color: #7200cc;
	margin-left: 10px;
}

.button-medium {
	display: block;
	max-width: 150px;
	background-color: #7200cc;
	color: #ffffff;
	border-radius: 4px;
	border-color: #7200cc;
	margin-left: 10px;
}

.button-modal {
	background-color: #7200cc;
	color: #ffffff;
	border-radius: 4px;
	border-color: #7200cc;
	padding: 12px;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 122%;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.buttonGroup {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;

	button {
		background-color: #7200cc;
		color: #ffffff;
		border-radius: 4px;
		border-color: #7200cc;
		padding: 12px;
		font-family: 'Inter', sans-serif;
		font-weight: 600;
		font-size: 16px;
		line-height: 122%;
		text-transform: uppercase;
	}
}
