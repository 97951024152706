.table-edit-main {
    display: flex;
    justify-content: center;
    position: fixed;
    inset: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.7);
    animation: showBD 0.3s ease-in-out;

    &__container {
        margin: 10% auto;
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1400px;
        min-height: 170px;
        padding: 20px;
        background: #ffffff;
        box-shadow: 0px 5px 15px rgba(5, 31, 43, 0.17);
        border-radius: 8px;
        animation: showModal 0.3s ease-in-out;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        svg {
            cursor: pointer;
        }
    }

    &__content {
        &__header-table {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: #0a65ff;

            &__item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                min-height: 30px;
                min-width: 150px;
                color: #ffffff;
                border-color: black;
                padding: 10px;

                &--big {
                    width: 100%;
                    max-width: 560px;
                }
                &--medium {
                    min-width: 150px;
                }
                &--bigm {
                    min-width: 180px;
                }
                &--sm {
                    min-width: 100px;
                }
            }
        }

        &__equals {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            min-width: 150px;
            &--medium {
                min-width: 150px;
                input {
                    font-family: "Inter", sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 120%;
                    color: #000000;
                    border: 1px solid #e3eeff;
                    border-radius: 4px;
                    padding: 12px 0;
                    width: 100%;
                    max-width: 150px;
                    text-indent: 5px;
                    outline: none;
                    background-color: #ffffff;
                }
            }

            &--big {
                width: 100%;
                max-width: 560px;
            }
            &--big-edit {
                min-width: 240px;
            }
            &--bigm {
                min-width: 180px;
            }
            &--sm {
                min-width: 100px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            &--payments {
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }
        }
    }
}

.table-map-main {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: stretch;
    &__equals {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: 150px;
        min-height: 30px;
        padding: 10px;
        &--big {
            width: 100%;
            max-width: 560px;
        }
        &--medium {
            min-width: 150px;
        }
        &--bigm {
            min-width: 180px;
        }
        &--sm {
            min-width: 100px;
            justify-content: center;
        }
        &--payments {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }
}

.table-edit-main__buttons {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    button {
        background-color: #0a65ff;
        color: #ffffff;
        border: none;
        max-width: 120px;
        padding: 10px;
        border-radius: 4px;
        &:disabled {
            background-color: gray;
            color: #ffffff;
            border-radius: 4px;
        }
    }
}

.edit-button {
    background-color: #0a65ff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    margin-left: 10px;
    &--delete {
        background-color: red;
        color: #ffffff;
        border-radius: 4px;
        border: none;
        margin: 10px 0;
    }
    &--save {
        background-color: #c3ee83;
        border-radius: 4px;
        border: none;
        margin: 10px auto;
    }
    &--cancel {
        background-color: #000;
        color: #ffffff;
        border-radius: 4px;
        border: 1px solid #000;
    }
    &--saveGateway {
        background-color: #c3ee83;
        border-radius: 4px;
        border: none;
        margin-left: 10px;
        color: #489019;
    }
    &--addGateway {
        background-color: #0a65ff;
        color: #ffffff;
        border: none;
        border-radius: 4px;
        margin: 10px auto;
        padding: 4px;
    }
}
