$white: #ffffff;
$black: #000000;
$tutor: #7200cc;
$iconTutor: #7200cc;
$bgLinkTutorHover: #a55cde;
$statusNew: #ffe68d;
$statusActive: #c4ed89;
$statusUnActive: #ffd5d5;
$filterBg: #eaf0f5;
$filterTitle: #2f2f2f;
$activeTab: #191919;
$grey: #828282;
$inputPlaceHolder: rgba(172, 172, 172, 1);

//colors
$bg: #f4f7fc;
$mainText: #000000;
$inputText: #999999;
$inputBorder: #e3eeff;
$btn: #7200cc;
$btnText: #ffffff;
$sideText: #0c4284;
$svgHover: #7200cc;
$bgText: #dae9fc;
$border: #dddddd;
$title: #0a376e;
$tableHeader: #606f89;
$btnStatusBl: #5584fc;
$btnStatusBgBl: #ddecfe;
$btnStatusGr: #489019;
$btnStatusBgGr: #c3ee83;
$btnStatusRed: #fc5569;
$btnStatusArbitration: #ff001e;
$btnStatusBgRed: #fee5dd;
$btnStatusYl: #fc9b55;
$btnStatusBgYl: #fef9dd;
