.popup {
    display: flex;
    justify-content: center;
    position: fixed;
    inset: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.7);
    animation: showBD 0.3s ease-in-out;
    align-items: center;
    
    .container-modal {
        margin: 30% auto;
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 430px;
        height: 170px;
        padding: 24px;
        background: #ffffff;
        box-shadow: 0px 5px 15px rgba(5, 31, 43, 0.17);
        border-radius: 8px;
        animation: showModal 0.3s ease-in-out;
    }

    .text {
        margin-top: 30px;
        height: 80px;
        h2 {
            text-align: center;
        }
    }

    .buttons {
        padding: 0 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-align: center;
        margin-bottom: 10px;

        &__item {
            width: 60px;
            height: 30px;
            background-color: #0A65FF;
            color: #ffffff;
            border-radius: 20px;
        }

        &__one {
            min-width: 60px;
            height: 30px;
            background-color: #0A65FF;
            color: #ffffff;
            border-radius: 20px;
            justify-content: center;
        }
    }
}


@keyframes showBD{
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes showModal {
    0% {
        transform: translateY(-500px);
    }
    100% {
        transform: translateY(0px);
    }
}