.modal {
  position: fixed;
  top: 35%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
  max-width: 280px;
  border: 1px solid $inputBorder;
  padding: 20px 20px 50px;
  border-radius: 20px;
  background-color: $btnText;
  z-index: 999;
  flex-direction: column;
  display: none;
  &.show {
    display: flex;
  }
  svg {
    stroke: $svgHover;
    height: 25px;
    width: 25px;
    align-self: flex-end;
    transition: all .3s linear;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  &__text {
    font-weight: 500;
    font-size: rem(18);
    line-height: 143%;
    color: $inputText;
    text-align: center;
    margin: 20px 0;
  }
  &__btns {
display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 250px;
  }
  &__btn {
    width: 100%;
    max-width: 100px;
    background-color: #F4F7FC;
    border-radius: 10px;
    padding: 10px 5px;
    text-align: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    color: $sideText;
    &:hover {
      background-color: $btn;
      color: $btnText;
    }
  }
  &__overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: .2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 900;
    display: none;
    &.show {
      display: block;
    }
  }
}

.hide {
  display: none;
}