.description {
    position: relative;
  &__area {
    border: 1px solid $inputBorder;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: rem(14);
    line-height: 122%;
    color: $mainText;
  }
  &__error {
    bottom: -17px;
  }
  & + & {
    margin-top: 32px;
  }
}