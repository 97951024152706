.file-input {
  display: flex;
  flex-direction: column;
  label {
    width: 100%;
    max-width: 300px;
    line-height: 122%;

    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: rem(12);
    line-height: 122%;
    color: $mainText;

    display: inline-block;
    padding: .5em .75em;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    background-color: #fdfdfd;
    cursor: pointer;
    border: 1px solid #ebebeb;
    border-bottom-color: #e2e2e2;
    border-radius: .25em;
  }

  input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip:rect(0,0,0,0);
    border: 0;
  }

  &__preview {
    margin-top: 5px;
    width: 300px;
    background-size: cover;
  }
}