.filters {
	background-color: $filterBg;
	border-radius: 10px;
	padding: 15px 20px 50px 15px;
	margin-bottom: 40px;

	&__title {
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 500;
		font-size: 20px;
		line-height: 130%;
		margin-bottom: 20px;
	}

	&__inner {
		.row {
			display: flex;
			align-items: flex-end;
			justify-content: flex-start;
		}
		.row:first-child {
			margin-bottom: 10px;
		}
	}
	&__input {
		max-width: 150px;
		padding-bottom: 0;
		.input__logo {
			display: none;
		}
		.input__item {
			padding: 5px;
		}
		.error {
			bottom: -13px;
			font-size: 12px;
		}
	}
	&__btn {
		max-width: 150px;
		padding: 7px 0;
		font-weight: 400;
		font-size: rem(12);
		text-transform: capitalize;

		&:active {
			opacity: 0.6;
		}
	}
	&__picker {
		font-family: 'Inter', sans-serif;
		font-weight: 300;
		font-size: rem(13);
		line-height: 122%;
		color: $mainText;
		border: 1px solid $inputBorder;
		border-radius: 4px;
		padding: 6px 0 7px;
		width: 150px;
		text-indent: 5px;
		outline: none;
		background-color: $btnText;
		height: 29px;
	}
	&__datapicker {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		margin-right: 30px;
	}
	&__select {
		width: 160px;
		margin-right: 30px;
	}
}

.rowFlex {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	margin-bottom: 30px;
	> div + div {
		margin-left: 20px;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.filter-dataPicker {
	height: 40px;
	border-radius: 4px;
	border: 1px solid #d5d5d5;
	font-size: 14px;
	padding: 5px;
	width: 146px;
	& + .react-datepicker__close-icon {
		padding: 5px;
		&:after {
			background-color: $tutor;
		}
		&:hover {
			&:after {
				background-color: #fff;
				color: $tutor;
				border: 1px solid $tutor;
			}
		}
	}
}
