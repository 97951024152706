.domain-textarea {
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	border: 1px solid #e3eeff;
	outline: 0;
	width: 300px;
	height: 74px;
	display: block;
	margin-bottom: 43px;
}
.domain-crt-select {
	max-width: 300px;
}

.domain-crt-select--mb {
	max-width: 300px;
	margin-bottom: 43px;
}

.domain-crt-page__container {
	display: flex;
	flex-direction: column;

	button + button {
		margin-top: 20px;
	}
}

.domain-crt-select--second {
	max-width: 300px;
	margin-top: 30px;
}

.button__add-product {
	width: 100%;
	padding: 10px 0;

	color: #fff;
	background-color: #7200cc;

	border-radius: 10px;
	border: none;

	&:hover {
		background-color: #fff;
		border: 1px solid #7200cc;
		color: #7200cc;
	}
}

.button__del-product {
	width: 100%;
	padding: 10px 0;

	background-color: #c0c0c0;
	border: 1px solid #7200cc;
	color: #fff;

	border-radius: 10px;

	&:hover :not(:disabled) {
		color: #c0c0c0;
		background-color: #fff;
	}

	&:disabled {
		opacity: 0.7;
	}
}

.add-paymentSettingsDomain {
	display: flex;
	align-items: center;
	height: 110px;
	margin-bottom: 40px;
	&--maassUpdate {
		display: flex;
		align-items: center;
		height: 110px;
		margin-bottom: 20px;
		justify-content: space-between;
	}

	&__paymentSystem {
		min-width: 600px;
		&--massUpdate {
			min-width: 400px;
		}
	}
	&__chance {
		margin-left: 20px;
	}

	&__btn {
		padding-top: 18px;
		margin-left: 20px;
		button {
			height: 82px;
			padding: 0 5px;
		}
	}
}

.add-sberBill {
	display: flex;
	align-items: center;
	&__paymentSystem {
		width: 400px;
	}
	&__btn {
		margin-left: 30px;
		button {
			padding: 10px;
		}
	}
}

.add-to-domain-payment-settings {
	display: flex;
	align-items: flex-end;
	margin-bottom: 43px;
	&--error {
		border: 1px solid red;
		border-radius: 10px;
	}

	&__input {
		padding: 0;
		margin: 0;
	}

	&__payment-system {
		height: 66px;
		min-width: 600px;

		strong {
			font-weight: 500;
		}
	}
	&__payment-gateway {
		height: 60px;
		margin-left: 20px;
	}
	&__payment-chance {
		height: 66px;
		margin-left: 20px;
	}
	&__button {
		margin-left: 20px;
	}

	&__button-2 {
		margin-left: 20px;
		height: 50px;
	}
}

.add-to-domain-payment-settings-title {
	margin-bottom: 16px;
}

.input-checkbox-noRef {
	display: flex;
	flex-direction: column;
	input[type='checkbox'] {
		width: 80px;
		height: 80px;
		margin-left: 20px;
	}
	label {
		margin-left: 20px;
		font-size: 14px;
	}
}

.add-payment-settings-error-message {
	padding: 6px;
	color: red;
	font-size: 14px;
}

.add-payment-settings-error-message-2 {
	color: red;
	font-size: 24px;
	line-height: 30px;
	margin: 0 0 20px;
}
