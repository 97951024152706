.header {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 15px 0 0 20px;
	margin-bottom: 30px;

	&__title {
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 500;
		font-size: 30px;
		line-height: 36px;
	}

	&__btn {
		padding: 12px 5px;
		font-size: rem(14);
		margin-left: auto;
		width: auto;
	}

	&__ava {
		width: 100%;
		max-width: 40px;
	}

	&__link {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: max-content;
		height: 40px;
		padding: 0 30px;
		background: #7200cc;
		border-radius: 4px;
		color: #fff;
		cursor: pointer;
		text-decoration: none;

		&:hover {
			background-color: #fff;
			color: #7200cc;
			border: 1px solid #7200cc;

			svg path {
				fill: #7200cc;
				stroke: #7200cc;
			}
		}

		svg + span {
			margin-left: 10px;
		}
	}

	&__container {
		display: flex;
		div + img {
			margin-left: 32px;
		}
		a + img {
			margin-left: 32px;
		}
	}
}

.header-second {
	display: flex;
	min-width: 380px;
	a + img {
		margin-left: 20px;
	}
}

.header-third {
	display: flex;
	min-width: 550px;
	a + img {
		margin-left: 20px;
	}
}
