.auth {
	padding: 50px 0;
	background-color: $bg;
	height: 100vh;
	form {
		input {
			max-width: 100%;
		}
		button {
			max-width: 100%;
		}
	}
	&__logo {
		margin: 0 auto;
		display: block;
	}
	&__inner {
		max-width: 360px;
		background-color: white;
		margin: 0 auto;
		width: 100%;
		transform: translateY(50%);
		padding: 40px 30px;
	}
	&__title {
		font-family: 'Inter', sans-serif;
		font-weight: 500;
		font-size: rem(18);
		line-height: 122%;
		color: $mainText;
		text-align: center;
		margin-bottom: 40px;
	}
	&__input {
		text-indent: 48px;
	}
	.input__label {
		display: none;
	}
	&__btn {
		svg {
			display: none;
		}
	}
}
