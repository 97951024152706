@import 'assets/scss/_variables.scss';

.container {
	display: flex;
	padding: 0 0 0 20px;
	margin-bottom: 20px;

	div + div {
		margin-left: 20px;
	}
}

.link {
	a {
		font-family: 'IBM Plex Sans', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 25px;
		line-height: 34px;
		text-decoration: none;
		color: $grey;
	}
}

.linkActive {
	a {
		font-size: 28px;
		line-height: 34px;
		color: $activeTab;
		font-weight: 500;
		border-bottom: 2px solid $iconTutor;
		text-decoration: none;
	}
}
