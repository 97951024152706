.filters-select {
  font-family: 'Inter';
  font-size: 14px;
  & &__control {
    min-height: 29px;
  }
  & &__value-container {
    padding: 0 8px;
  }
  & &__indicator {
    padding: 0 4px;
  }
}

.filters-select-domain {
  font-family: 'Inter';
  font-size: 14px;
  & &__control {
    min-height: 38px;
  }
  & &__value-container {
    padding: 0 8px;
  }
  & &__indicator {
    padding: 0 4px;
  }
}