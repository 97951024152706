.selectWrapper {
	label {
		width: 100%;
		display: flex;
		flex-direction: column;

		span {
			font-family: 'IBM Plex Sans', sans-serif;
			font-weight: 400;
			font-size: 12px;
			line-height: 12px;
			color: #585858;
			margin-bottom: 6px;
		}
	}
}
