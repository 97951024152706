@import 'assets/scss/_variables.scss';

.sideBarLink {
	width: 100%;
	height: 52px;
	padding: 15px 12px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	transition: all 0.3s ease-in-out;
	text-decoration: none;
	min-width: max-content;

	&:hover {
		background-color: $bgLinkTutorHover;
		border-radius: 10px;
		svg path {
			stroke: $white;
		}
	}
	&:hover &__text {
		color: $white;
	}

	svg path {
		fill: none;
		transition: 0.3s ease-in-out;
		stroke: $iconTutor;
		stroke-width: 1.5;
	}
	svg {
		width: 24px;
	}

	&__text {
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 400;
		font-size: 20px;
		line-height: 26px;
		color: $black;
		text-decoration: none;
		margin-left: 16px;
	}

	&__active {
		background-color: $tutor;
		border-radius: 10px;
		svg path {
			stroke: $white;
		}
		.sideBarLink__text {
			color: $white;
		}
	}
}
