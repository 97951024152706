.gateway-crt {
  &-label {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: rem(12);
    line-height: 122%;
    color: $mainText;
    margin-top: 43px;
  }

  &__applepay {
    width: 100%;
    height: 200px;
    margin-bottom: 40px;
  }

  .gateway-crt-btn {
    margin-top: 20px;
    &:disabled {
      opacity: 0.6;
    }
  }
  &__input {
    margin-top: 10px;
    width: 100%;

    .input__item {
      width: 100%;
      max-width: 100%;
    }
  }

  &__input-appeals {
    margin-top: 10px;
    width: 300px;
  }
}
