.order {
  &__pagination {
    display: flex;
    justify-content: flex-end;

    .rc-pagination-item-active {
      border-color: $btn;

      a {
        color: $btn;
      }
    }
  }
  &__button {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 40px;

    button {
      width: 100%;
      max-width: max-content;
      border: none;
      cursor: pointer;
      background-color: #28e00c;
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 122%;
      color: #ffffff;
      text-transform: uppercase;
      padding: 12px;
      text-align: center;
      border-radius: 4px;
      text-decoration: none;
    }
  }
}
