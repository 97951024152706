.input-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;

	label {
		font-family: 'IBM Plex Sans', sans-serif;
		font-weight: 400;
		font-size: 12px;
		line-height: 12px;
		color: #585858;
		margin-bottom: 6px;
	}

	input {
		width: 100%;
		box-shadow: 0px 1px 4px rgba(167, 167, 167, 0.1);
		border: 1px solid transparent;
		padding: 11px 16px 9px;
		-webkit-box-shadow: 0 1px 4px rgba(167, 167, 167, 0.1);
		-moz-box-shadow: 0 1px 4px rgba(167, 167, 167, 0.1);
		appearance: none;

		font-family: 'Roboto', sans-serif;
		font-size: 14px;
		line-height: 18px;
	}
}
