.loaderImg {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&__logo {
		margin-bottom: 22px;
	}
	&__loading {
		width: 128px;
		height: 4px;
		overflow: hidden;
		background-color: #dddddd;
		margin: 0 auto;
		border-radius: 8px;
		-o-border-radius: 8px;
		-ms-border-radius: 8px;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
	}
	&__loadingProgress {
		background-color: #7200cc;
		margin-top: 0;
		margin-left: 0;
		animation-name: bounce_loadingProgressG;
		-o-animation-name: bounce_loadingProgressG;
		-ms-animation-name: bounce_loadingProgressG;
		-webkit-animation-name: bounce_loadingProgressG;
		-moz-animation-name: bounce_loadingProgressG;
		animation-duration: 2s;
		-o-animation-duration: 2s;
		-ms-animation-duration: 2s;
		-webkit-animation-duration: 2s;
		-moz-animation-duration: 2s;
		animation-iteration-count: infinite;
		-o-animation-iteration-count: infinite;
		-ms-animation-iteration-count: infinite;
		-webkit-animation-iteration-count: infinite;
		-moz-animation-iteration-count: infinite;
		animation-timing-function: linear;
		-o-animation-timing-function: linear;
		-ms-animation-timing-function: linear;
		-webkit-animation-timing-function: linear;
		-moz-animation-timing-function: linear;
		width: 128px;
		height: 4px;
	}
}

@keyframes bounce_loadingProgressG {
	0% {
		margin-left: -128px;
	}

	100% {
		margin-left: 128px;
	}
}

@-o-keyframes bounce_loadingProgressG {
	0% {
		margin-left: -128px;
	}

	100% {
		margin-left: 128px;
	}
}

@-ms-keyframes bounce_loadingProgressG {
	0% {
		margin-left: -128px;
	}

	100% {
		margin-left: 128px;
	}
}

@-webkit-keyframes bounce_loadingProgressG {
	0% {
		margin-left: -128px;
	}

	100% {
		margin-left: 128px;
	}
}

@-moz-keyframes bounce_loadingProgressG {
	0% {
		margin-left: -128px;
	}

	100% {
		margin-left: 128px;
	}
}
