.promoPage {
	width: 100%;
	display: flex;

	&__wrapper {
		width: 100%;
		padding: 20px;
		border-radius: 20px;
		background-color: #eaf0f5;

		h2 {
			font-weight: 600;
			margin-bottom: 20px;
		}

		h3 {
			margin-bottom: 10px;
		}

		div + h3 {
			margin-top: 10px;
		}
	}

	&__wrapper + &__wrapper {
		margin-top: 20px;
	}

	&__container {
		display: flex;
		justify-content: space-between;
		gap: 40px;
	}

	&__courseCard + &__courseCard {
		margin-top: 20px;
	}

	&__fields {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	&__card {
		display: flex;
		align-items: center;
		gap: 40px;

		svg {
			cursor: pointer;
		}
	}

	&__img {
		height: 200px;
	}

	&__button {
		margin-top: 20px;
		display: flex;
		align-items: flex-start;
		gap: 20px;

		button {
			width: 100%;
			max-width: max-content;
			height: 39px;

			background: #7200cc;
			color: #ffffff;
			border-radius: 4px;
			border: none;

			font-family: 'IBM Plex Sans', sans-serif;
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
			padding: 10px 24px;

			&:disabled {
				opacity: 0.3;
			}

			&:hover {
				&:not(:disabled) {
					box-sizing: border-box;
					background-color: #ffffff;
					color: #7200cc;
					border: 1px solid #7200cc;
				}
			}
		}
	}
}
