.statistics-chart {
	flex-shrink: 0;

	& + & {
		margin-top: 48px;
	}
}

.statistics-title {
	font-size: 18px;
	margin-bottom: 12px;
}

.statistics-btn {
	width: 100%;
	max-width: max-content;
	padding: 11px 20px;
	background-color: #7200cc;
	color: #ffffff;
	border-radius: 10px;
	border: none;
	&:hover {
		background-color: #fff;
		color: #7200cc;
		border: 1px solid #7200cc;
	}
}
