.user {
    &__pagination {
      display: flex;
      justify-content: flex-end;
  
      .rc-pagination-item-active {
        border-color: $btn;
  
        a {
          color: $btn;
        }
      }
    }
  }
  