.editModalDomain {
    display: flex;
    justify-content: center;
    position: fixed;
    inset: 0;
    z-index: 500;
    background: rgba(0, 0, 0, 0.7);
    animation: showBD 0.3s ease-in-out;
    align-items: center;
    border: 1px solid #000;

    &__container {
        margin: 30% auto;
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 1000px;
        height: 750px;
        padding: 24px;
        background: #ffffff;
        box-shadow: 0px 5px 15px rgba(5, 31, 43, 0.17);
        border-radius: 8px;
        animation: showModal 0.3s ease-in-out;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        &__close {
            cursor: pointer;
        }
    }

    &__selectStylePage {
        display: flex;
        justify-content: space-between;
    }

    &__table {
        height: 240px;
        overflow-y: auto;
    }

    &__buttons {
        text-align: end;
        button {
            border-radius: 4px;
            border: none;
            color: #ffffff;
            max-width: 150px;
            padding: 7px 10px;
            font-weight: 400;
            font-size: 0.75rem;
            margin-left: 20px;
        }
        &__cancel {
            background-color: rgb(240, 72, 72);
        }
        &__add {
            background-color: #0a65ff;
            &:disabled {
                background-color: gray;
            }
        }
    }
}
