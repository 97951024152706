.editGatewayModal {
  display: flex;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: 500;
  background: rgba(0, 0, 0, 0.7);
  animation: showBD 0.3s ease-in-out;
  align-items: center;
  border: 1px solid #000;

  &__container {
    margin: 30% auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 1000px;
    height: auto;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(5, 31, 43, 0.17);
    border-radius: 8px;
    animation: showModal 0.3s ease-in-out;
    justify-content: space-between;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    &__close {
      cursor: pointer;
    }
  }

  &__table {
    height: 240px;
    overflow-y: auto;
  }

  &__select-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 30px 0;
  }

  &__select-container {
    width: 300px;
    padding-right: 20px;
  }

  &__buttons {
    text-align: end;
    button {
      border-radius: 4px;
      border: none;
      color: #ffffff;
      max-width: 150px;
      padding: 7px 10px;
      font-weight: 400;
      font-size: 0.75rem;
      margin-left: 20px;
    }
    &__cancel {
      background-color: rgb(240, 72, 72);
    }
    &__add {
      background-color: #0a65ff;
      &:disabled {
        background-color: gray;
      }
    }
  }

  &__massUpdate {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__checkbox {
    display: flex;
    flex-direction: column;
    input[type='checkbox'] {
      width: 80px;
      height: 80px;
    }
    label {
      font-size: 14px;
    }
  }
}
