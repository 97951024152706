.create {
  .product-crt-select {
    padding: 0 0 43px;
  }
  .create-file-input {
    margin-bottom: 43px;
  }
  .recalculate-checkbox {
    padding: 0 0 43px;
  }
  &__textarea {
    padding-bottom: 43px;
    textarea {
      min-width: 600px;
      min-height: 300px;
      border: 1px solid #e3eeff;
      overflow: auto;
    }
  }
}

.textarea-checkboxValue {
  min-width: 1200px;
  display: flex;
  justify-content: space-between;
}

.textarea-strikes {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-bottom: 40px;
  
  textarea {
    height: 100px;
    border-color: $inputBorder;
  }
}

.error-message {
  padding: 10px 0;
  color: red;
}