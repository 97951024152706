.button {
	width: 100%;
	max-width: 212px;
	height: 40px;
	background: #7200cc;
	color: #ffffff;
	border-radius: 4px;
	border: none;
	font-family: 'IBM Plex Sans', sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	padding: 7px 64px 10px;

	&:hover {
		background-color: #fff;
		color: #7200cc;
		border: 1px solid #7200cc;
	}
}
