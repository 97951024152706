.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $btn;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	font-size: rem(16);
	line-height: 122%;
	color: $btnText;
	text-transform: uppercase;
	padding: 12px 0;
	text-align: center;
	border-radius: 4px;
	text-decoration: none;
	width: 100%;
	border: none;
	cursor: pointer;
	svg {
		margin-right: 10px;
		width: 20px;
	}
}

.btn-back {
	width: 300px;
	text-align: center;
	margin-top: 20px;
	color: $btn;
	background-color: #fff;
}
