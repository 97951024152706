@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-LightItalic.eot');
	src: local('Gotham Light Italic'), local('Gotham-LightItalic'),
		url('../fonts/Gotham/Gotham-LightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-LightItalic.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-LightItalic.woff') format('woff'),
		url('../fonts/Gotham/Gotham-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-BoldItalic.eot');
	src: local('Gotham Bold Italic'), local('Gotham-BoldItalic'),
		url('../fonts/Gotham/Gotham-BoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-BoldItalic.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-BoldItalic.woff') format('woff'),
		url('../fonts/Gotham/Gotham-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-XLight.eot');
	src: local('Gotham ExtraLight'), local('Gotham-XLight'),
		url('../fonts/Gotham/Gotham-XLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-XLight.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-XLight.woff') format('woff'),
		url('../fonts/Gotham/Gotham-XLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-Thin.eot');
	src: local('Gotham Thin'), local('Gotham-Thin'),
		url('../fonts/Gotham/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-Thin.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-Thin.woff') format('woff'),
		url('../fonts/Gotham/Gotham-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-Book.eot');
	src: local('Gotham Book'), local('Gotham-Book'),
		url('../fonts/Gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-Book.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-Book.woff') format('woff'),
		url('../fonts/Gotham/Gotham-Book.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-ThinItalic.eot');
	src: local('Gotham Thin Italic'), local('Gotham-ThinItalic'),
		url('../fonts/Gotham/Gotham-ThinItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-ThinItalic.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-ThinItalic.woff') format('woff'),
		url('../fonts/Gotham/Gotham-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-MediumItalic.eot');
	src: local('Gotham Medium Italic'), local('Gotham-MediumItalic'),
		url('../fonts/Gotham/Gotham-MediumItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-MediumItalic.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-MediumItalic.woff') format('woff'),
		url('../fonts/Gotham/Gotham-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham Ultra';
	src: url('../fonts/Gotham/Gotham-UltraItalic.eot');
	src: local('Gotham Ultra Italic'), local('Gotham-UltraItalic'),
		url('../fonts/Gotham/Gotham-UltraItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-UltraItalic.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-UltraItalic.woff') format('woff'),
		url('../fonts/Gotham/Gotham-UltraItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-BookItalic.eot');
	src: local('Gotham Book Italic'), local('Gotham-BookItalic'),
		url('../fonts/Gotham/Gotham-BookItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-BookItalic.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-BookItalic.woff') format('woff'),
		url('../fonts/Gotham/Gotham-BookItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-BlackItalic.eot');
	src: local('Gotham Black Italic'), local('Gotham-BlackItalic'),
		url('../fonts/Gotham/Gotham-BlackItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-BlackItalic.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-BlackItalic.woff') format('woff'),
		url('../fonts/Gotham/Gotham-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-Light.eot');
	src: local('Gotham Light'), local('Gotham-Light'),
		url('../fonts/Gotham/Gotham-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-Light.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-Light.woff') format('woff'),
		url('../fonts/Gotham/Gotham-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-Bold.eot');
	src: local('Gotham Bold'), local('Gotham-Bold'),
		url('../fonts/Gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-Bold.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-Bold.woff') format('woff'),
		url('../fonts/Gotham/Gotham-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-Medium.eot');
	src: local('Gotham Medium'), local('Gotham-Medium'),
		url('../fonts/Gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-Medium.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-Medium.woff') format('woff'),
		url('../fonts/Gotham/Gotham-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Ultra';
	src: url('../fonts/Gotham/Gotham-Ultra.eot');
	src: local('Gotham Ultra'), local('Gotham-Ultra'),
		url('../fonts/Gotham/Gotham-Ultra.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-Ultra.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-Ultra.woff') format('woff'),
		url('../fonts/Gotham/Gotham-Ultra.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-XLightItalic.eot');
	src: local('Gotham ExtraLight Italic'), local('Gotham-XLightItalic'),
		url('../fonts/Gotham/Gotham-XLightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-XLightItalic.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-XLightItalic.woff') format('woff'),
		url('../fonts/Gotham/Gotham-XLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham/Gotham-Black.eot');
	src: local('Gotham Black'), local('Gotham-Black'),
		url('../fonts/Gotham/Gotham-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-Black.woff2') format('woff2'),
		url('../fonts/Gotham/Gotham-Black.woff') format('woff'),
		url('../fonts/Gotham/Gotham-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	outline: none;
}

/* make sure to set some focus styles for accessibility */
:focus {
	outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-focus-ring-color: rgba(255, 255, 255, 0);
	-webkit-text-size-adjust: none;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	-webkit-appearance: none;
	-moz-appearance: none;
}

input[type='search'] {
	-webkit-appearance: none;
	-moz-appearance: none;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}

textarea {
	overflow: auto;
	vertical-align: top;
	resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
	display: none;
	height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
	display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
	font-size: 100%;
	/* 1 */
	-webkit-text-size-adjust: 100%;
	/* 2 */
	-ms-text-size-adjust: 100%;
	/* 2 */
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
	outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
	border: 0;
	/* 1 */
	-ms-interpolation-mode: bicubic;
	/* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
	margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
	margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
	border: 0;
	/* 1 */
	padding: 0;
	white-space: normal;
	/* 2 */
	*margin-left: -7px;
	/* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
	font-size: 100%;
	/* 1 */
	margin: 0;
	/* 2 */
	vertical-align: baseline;
	/* 3 */
	*vertical-align: middle;
	/* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `` in
 * the UA stylesheet.
 */
button,
input {
	line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
	text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: button;
	/* 2 */
	cursor: pointer;
	/* 3 */
	*overflow: visible;
	/* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
	cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	/* 1 */
	padding: 0;
	/* 2 */
	*height: 13px;
	/* 3 */
	*width: 13px;
	/* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
	-webkit-appearance: textfield;
	/* 1 */
	-webkit-box-sizing: content-box;
	/* 2 */
	box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
	overflow: auto;
	/* 1 */
	vertical-align: top;
	/* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html,
button,
input,
select,
textarea {
	color: #222;
}

::-moz-selection {
	background: #b3d4fc;
	text-shadow: none;
}

::selection {
	background: #b3d4fc;
	text-shadow: none;
}

img {
	vertical-align: middle;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

textarea {
	resize: vertical;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Gotham', sans-serif;
	font-size: 16px;
}

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-weight: normal;
	margin: 0;
	padding: 0;
}

ul,
li {
	padding: 0;
	margin: 0;
}

.container {
	display: flex;
	height: 100%;
}
.container__content {
	padding: 0 24px 40px 20px;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.button:disabled {
	background-color: #c0c0c0;
}

.button-dis {
	max-width: 300px;
	background-color: #989a9e;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 122%;
	color: #ffffff;
	text-transform: uppercase;
	padding: 12px 0;
	text-align: center;
	border-radius: 4px;
	text-decoration: none;
	width: 100%;
	border: none;
	cursor: pointer;
}
