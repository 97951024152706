.wrapper {
	label {
		width: 100%;
		max-width: 146px;
		display: flex;
		flex-direction: column;

		span {
			font-family: 'IBM Plex Sans', sans-serif;
			font-weight: 400;
			font-size: 12px;
			line-height: 12px;
			color: #585858;
			margin-bottom: 6px;
		}

		input {
			box-sizing: border-box;
			width: 146px;
			height: 40px;
			border: 1px solid #d5d5d5;
			border-radius: 4px;
			padding: 7px 10px 10px;

			&::placeholder {
				font-family: 'IBM Plex Sans', sans-serif;
				font-weight: 400;
				font-size: 16px;
				line-height: 22px;
			}
		}
	}
}
