.dropDown {
  margin-bottom: 43px;
  position: relative;
  &.down {
    z-index: 666;
  }
  &__item {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: rem(14);
    line-height: 122%;
    color: $inputText;
    border: 1px solid $inputBorder;
    border-radius: 4px;
    padding: 12px 5px;
    width: 100%;
    max-width: 300px;
    outline: none;
    background-color: $btnText;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &.down svg {
      transform: rotate(180deg);
    }
    &.down {
      z-index: 666;
      position: relative;
    }
  }
  & svg {
    width: 15px;
    height: 15px;
    fill: $inputText;
    transition: all .4s linear;
  }
  &__list {
    border: 1px solid $inputBorder;
    width: 100%;
    max-width: 300px;
    display: none;
    max-height: 300px;
    overflow-y: auto;
    &.down {
      display: block;
      z-index: 666;
      position: relative;
    }
  }
  &__list-item {
    color: $mainText;
    font-weight: 300;
    font-size: rem(14);
    line-height: 122%;
    padding: 12px 5px;
    cursor: pointer;
    &.down {
      z-index: 666;
      position: relative;
    }
    &:nth-child(even) {
      background-color: $bg;
    }
  }
  &__checkbox {
    margin-right: 5px;
  }
  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: none;
    &.down {
      display: block;
    }
  }
  &__error {
    bottom: -20px;
    font-size: rem(12);
  }
}