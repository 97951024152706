.calendar {
  position: relative;
  &__dataPicker {
    position: absolute;
    display: none;
    top: 55px;
  }
}
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: none;
}
.hide {
  display: none;
}
.show {
  display: inline-block;
  z-index: 666;
}