.loader {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	// min-height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.7);
	z-index: 999;

	&__body {
		border: 4px solid #7200cc;
		border-top-color: transparent;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		animation: spin 1s linear infinite;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
