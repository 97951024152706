.ErrorsChainsTable {
	font-size: 14px;
	td,
	th {
		border: 1px solid #ddd;
		padding: 8px;
	}

	tr:nth-child(even) {
		background-color: #f2f2f2;
	}

	tr:hover {
		background-color: #ddd;
	}

	th {
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: left;
		background-color: #7200cc;
		color: white;
	}

	margin-bottom: 20px;
}

.errors {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.btn {
	background-color: #7200cc;
	width: max-content;
	padding: 8px 12px;
	color: #fff;
	border: none;

	&:disabled {
		opacity: 0.4;
	}
}

.active {
	color: #489019;
	background: #c3ee83;
}

.unactive {
	color: #fc5569;
	background: #fee5dd;
}

.currency {
	font-family: 'Inter', sans-serif;
}
