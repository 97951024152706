.orderPaymentModal {
  display: flex;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: 500;
  background: rgba(0, 0, 0, 0.7);
  animation: showBD 0.3s ease-in-out;
  align-items: center;
  border: 1px solid #000;

  &__container {
    margin: 30% auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 1000px;
    height: auto;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(5, 31, 43, 0.17);
    border-radius: 8px;
    animation: showModal 0.3s ease-in-out;
    justify-content: space-between;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &__close {
      cursor: pointer;
    }
  }

  &__containerField {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
  }

  &__input-wrapper {
    & + & {
      margin-left: 20px;
    }
    label {
      display: flex;
      flex-direction: column;
    }

    span {
      margin-bottom: 10px;
    }

    input {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 18px;
      width: 240px;
      padding: 12px 14px;
      border-radius: 4px;
      border: 1px solid #e3eeff;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    button {
      border-radius: 4px;
      border: none;
      color: #ffffff;
      max-width: 150px;
      padding: 7px 10px;
      font-weight: 400;
      font-size: 0.75rem;
      margin-left: 20px;
    }
    &__cancel {
      background-color: rgb(240, 72, 72);
    }
    &__add {
      background-color: #0a65ff;
      &:disabled {
        background-color: gray;
      }
    }
  }
}
