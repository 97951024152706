.autintification {
	&__group-button {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding-left: 20px;

		div {
			font-family: 'IBM Plex Sans', sans-serif;
			font-size: 25px;
			line-height: 34px;
			margin-bottom: 20px;
		}

		button {
			font-family: 'IBM Plex Sans', sans-serif;
			font-size: 16px;
			line-height: 22px;
			background: #7200cc;
			border-radius: 4px;
			color: #ffffff;
			border: none;
			width: 180px;
			height: 40px;

			&:hover {
				background-color: #fff;
				color: #7200cc;
				border: 1px solid #7200cc;
			}
		}
	}

	&__error {
		color: #ff5858;
		margin: 30px 0 15px;
	}

	&__success {
		color: #49c500;
		margin: 30px 0 15px;
	}

	&__qrCode {
		width: 160px;
	}
}
