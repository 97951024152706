.tab {
  padding: 11px 10px;
  position: relative;
  max-width: 200px;
  font-size: 18px;
  font-weight: bold;
  color: black;

  flex: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  border-style: solid;
  cursor: pointer;
  border-color: black;
  margin-bottom: 20px;

  &--active {
    border-color: #999999;
  }

  &--up {
    border-top-width: 1px;
    top: 0;
  }

  &--down {
    border-bottom-width: 1px;
    top: 0;
  }

  &--up--active {
    border-top-width: 3px;
  }

  &--down--active {
    border-bottom-width: 3px;
  }

  &--active {
    border-color: #0a65ff;
  }
}