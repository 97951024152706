.table {
  margin-bottom: 60px;

  &__header {
    border-bottom: 1px solid $tableHeader;
  }
  // &__inner {
  // }
}

.chargeback {
  background-color: $btnStatusRed;
}

.arbitration {
  background-color: $btnStatusArbitration;
}

.tableRow {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 17px 0 17px 23px;
  text-decoration: none;
  &:nth-child(even) {
    background-color: $bg;
  }

  &__link {
    padding-right: 5px;
  }

  &__item {
    font-family: 'Inter', sans-serif;
    line-height: 122%;
    min-width: 145px;
    font-weight: 400;
    font-size: rem(12);
    color: $mainText;
    //white-space: nowrap;
    padding: 0 5px;

    &__button {
      font-family: 'Inter', sans-serif;
      line-height: 122%;
      min-width: 145px;
      font-weight: 400;
      font-size: rem(12);
      color: $mainText;
      //white-space: nowrap;
      padding: 0 5px;
      cursor: pointer;
    }

    &--header {
      font-weight: 600;
      font-size: rem(12);
      text-transform: uppercase;
      color: $tableHeader;
    }
    &--big {
      width: 100%;
    }
    &--bigw {
      min-width: 320px;
      width: 100%;
    }
    &--charge {
      width: 100%;
      max-width: 400px;
    }
    &--charge2 {
      width: 100%;
      max-width: 220px;
    }
    &--sm {
      min-width: 50px;
    }
    &--m {
      min-width: 80px;
    }

    &--mm {
      min-width: 120px;
    }

    &--medium {
      max-width: 150px;
    }

    &--stats {
      min-width: 180px;
    }

    &--reason {
      width: 200px;
    }

    &--date {
      max-width: 140px;
    }

    &--status {
      min-width: 60px;
    }

    &--svg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &--rigth {
      text-align: end;
    }

    &--rigthm {
      text-align: end;
      min-width: 100px;
    }
    &--rigthmr {
      text-align: end;
      min-width: 100px;
      margin-right: 10px;
    }
    &--rigthmm {
      text-align: end;
      min-width: 130px;
      margin-right: 10px;
    }
    &--hm {
      min-width: 160px;
    }

    &--chargeback {
      text-align: end;
      min-width: 110px;
    }

    &--sort {
      display: flex;
      cursor: pointer;
      padding-right: 10px;
      svg {
        width: 10px;
      }
    }
  }
  &__svg-bin {
    fill: black;
    transition: all 0.3s ease-in-out;
    &:hover {
      fill: red;
    }
  }
  &__svg-pen {
    fill: black;
    transition: all 0.3s ease-in-out;
    &:hover {
      fill: $svgHover;
    }
  }
  &__img {
    width: 20px;
    margin: 0 8px;
  }
  &__item-btn {
    border-radius: 4px;
    padding: 5px 0;
    width: 100%;
    max-width: 71px;
    font-size: rem(10);
    display: inline-block;
    text-align: center;
    &--blue {
      color: $btnStatusBl;
      background: $btnStatusBgBl;
    }
    &--green {
      color: $btnStatusGr;
      background: $btnStatusBgGr;
    }
    &--red {
      color: $btnStatusRed;
      background: $btnStatusBgRed;
    }
    &--yellow {
      color: $btnStatusYl;
      background: $btnStatusBgYl;
    }
  }
  &__showmore {
    color: $btnText;
    background: $btn;
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    display: inline-block;
  }
  &__showmore-text {
    display: block;
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.2s linear;
    &.slide {
      max-height: 1000px;
      // transition: max-height 2s linear;
    }
  }
  &__item-content {
    margin: 15px 0;
  }

  //  for admin roles tab
  .roles-tab {
    &__name {
      font-weight: bold;
      margin-right: 10px;
    }

    &__input {
      margin-right: 5px;
      label {
        margin-right: 5px;
      }
    }
  }
}

.btn-status {
  display: block;
  max-width: 100px;
  height: 25px;
  background-color: #0a65ff;
  color: #ffffff;
  border-radius: 4px;
  border-color: #0a65ff;
  &:hover {
    background-color: #ffffff;
    color: #0a65ff;
  }
}

.btn-status-css {
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 100px;
  height: 25px;
  background-color: #0a65ff;
  color: #ffffff;
  border-radius: 4px;
  border-color: #0a65ff;
  &:hover {
    background-color: #ffffff;
    color: #0a65ff;
  }
}

.strikes-tooltip {
  display: flex;
  margin-bottom: 10px;
  pre {
    margin-left: 10px;
  }
}

.status-active {
  display: inline-block;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
}

.status-inactive {
  display: inline-block;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}

.shaker-settings {
  display: flex;
  align-items: center;
}

.checkAll {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: rem(12);
  font-weight: bold;

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: rem(12);
    font-weight: bold;
    button {
      background-color: #0a65ff;
      border-radius: 4px;
      border: none;
      color: #ffffff;
      max-width: 150px;
      padding: 7px 10px;
      font-weight: 400;
      font-size: rem(12);
      &:active {
        opacity: 0.6;
      }
      &:disabled {
        background-color: gray;
      }
    }
  }
}

.limids {
  display: flex;
  font-family: monospace;
  justify-content: space-between;
  span {
    text-align: right;
    width: 40%;
  }
  &--bold {
    font-weight: bold;
  }
}

.limids2 {
  display: flex;
  font-family: monospace;
  justify-content: flex-end;
}

.li-mids {
  display: flex;
  font-family: monospace;
  justify-content: flex-end;
  font-weight: bold;
}

.tableRowCheck {
  align-items: center;
}

#myHeader {
  &.sticky {
    position: fixed;
    background-color: #ffffff;
    top: 0;
  }
}

.domain {
  &__noRef {
    border-bottom: 1px dashed black;
    font-style: italic;
  }
}

.logoSort {
  padding-left: 10px;
}
