.create {
  padding: 40px 0 0 29px;

  &__title {
    font-weight: 400;
    font-size: rem(32);
    line-height: 122%;
    color: $title;
    margin-bottom: 40px;
  }

  &__input {
    display: flex;
    flex-direction: column;

    svg {
      display: none;
    }

    .error {
      font-size: 12px;
    }
  }
  
  &__btn {
    max-width: 300px;
    margin: 10px 10px 10px auto;
  }

  &__prev {
    font-family: 'Inter', sans-serif;
    background: none;
    color: $btn;
    text-align: left;
    margin-top: 20px;
    display: block;
    text-decoration: none;
    font-weight: 600;
    font-size: rem(16);
    line-height: 122%;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 40px;
  }
  
  &__info {
    margin-bottom: 20px;
  }
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
  }
  &__textfield-wrapper {
    & + & {
      margin-top: 16px;
    }
  }
  &__inputs {
    width: 100%;
    max-width: 300px;
  }
  &__pixelDescr {
    font-weight: 300;
    font-size: rem(14);
    line-height: 122%;
    color: $inputText;
    border: 1px solid $inputBorder;
    border-radius: 4px;
    padding: 12px 5px;
    width: 100%;
    max-width: 300px;
    margin-bottom: 43px;
  }
  &__label {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: rem(12);
    line-height: 122%;
    color: $mainText;
  }

  &__label-transfer {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: rem(16);
    line-height: 150%;
    color: $mainText;
  }

  &__roles {
    margin-bottom: 10px;

    &__title {
      font-weight: bold;
      margin-right: 10px;
    }

    &__input {
      margin-right: 5px;
      label {
        margin-right: 5px;
      }
    }
  }
}