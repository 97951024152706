.sideBar {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
  height: max-content;
	width: 100%;
	max-width: max-content;
	padding: 15px 12px;

	&__logo {
		width: 100%;
		max-width: 160px;
		margin-bottom: 20px;
	}
}
