.input {
  padding-bottom: 43px;
  position: relative;
  &__icon {
    position: absolute;
    top: calc(50% - 30px);
    width: 20px;
    left: 12px;

    path {
      stroke: $inputText;
      fill: none;
    }
  }
  &__item {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: rem(14);
    line-height: 122%;
    color: $mainText;
    border: 1px solid $inputBorder;
    border-radius: 4px;
    padding: 12px 0;
    width: 100%;
    max-width: 300px;
    text-indent: 5px;
    outline: none;
    background-color: $btnText;
    &::placeholder {
      color: $inputText;
      text-transform: uppercase;
    }
    &:disabled {
      background-color: #f2f2f2;
    }
  }

  &__item-2 {
    input {
      height: 50px;
    }
  }

  &__chance {
    padding-bottom: 0;
    input {
      height: 82px;
    }
  }

  &__label {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: rem(12);
    line-height: 122%;
    color: $mainText;
  }
  &__wrapper {
    position: relative;
  }
  &__clear-icon {
    position: absolute;
    right: 4px;
    top: 4px;
    &:hover {
      path{
        fill: hsl(0, 0%, 70%);
      }
    }

    path {
      stroke: none;
      fill: hsl(0, 0%, 80%);
    }
  }
}
.error {
  position: absolute;
  color: red;
  bottom: 25px;
  //display: none;
}

@media (max-width: 580px) {
  .input {
    padding-bottom: 23px;
    &__item {
      font-size: rem(16);
      padding: 10px 0;
      text-indent: 8px;
    }
  }
}

.input-checkbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  input[type="checkbox"] {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  label {
    font-size: 14px;
  }
}

.input-checkbox2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 0 0;
  input[type="checkbox"] {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  label {
    font-size: 14px;
  }
}
